body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  min-height: calc(100vh - 122px);
}

nav .container{
  min-height: initial;
}

nav.bg-header{
  background-color: #D9AD57;
}

body #root p, body #root h1, body #root h2,body #root h3,body #root h4,body #root h5,td,th{
  font-family: 'Noto Sans JP', sans-serif;
}

.h2-design {
  padding: .5em .7em;
  font-weight: bold;
  border-left: 5px solid #fd8f36;
  color: #333333;
}

.lp-bg {
  background-color: #D9AD57;
}

.balloon5 {
  width: 100%;
  margin: 1.5em 0;
  overflow: hidden;
}

.balloon5 .faceicon {
  float: left;
  margin-right: -90px;
  width: 80px;
}

.balloon5 .faceicon img, .balloon5 .faceicon-right img{
  width: 100%;
  height: auto;
  border: solid 3px #d7ebfe;
  border-radius: 50%;
}

.balloon5 .chatting {
  width: 100%;
}

.balloon5 .chatting.right {
  float: left;
}

.says {
  display: inline-block;
  position: relative; 
  padding: 17px 13px;
  border-radius: 12px;
  background: #d7ebfe;
}

.says:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 18px; 
  left: -24px;
  border: 12px solid transparent;
  border-right: 12px solid #d7ebfe;
}

.says p,.says-right p {
  margin: 0;
  padding: 0;
}

.says-right {
  position: relative; 
  padding: 17px 13px;
  border-radius: 12px;
  background: #d7ebfe;
}

.says-right:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 18px; 
  right: -22px;
  border: 12px solid transparent;
  border-left: 12px solid #d7ebfe;
}

.balloon5 .faceicon-right {
  margin-left: -90px;
  width: 80px;
  display: inline-block;
}